<template>
  <section class="homepage">
    <div class="header d-flex align-items-center justify-content-center">
      <div class="content">
        <h1>Connecting talent to <span>growth businesses</span></h1>
        <div class="sub-title">
          Welcome to Comstream. We specialize in finding and placing the most suitable IT
          professionals for leading companies, hand-selected and vetted from a pool of highly
          qualified candidates.
        </div>
      </div>
      <div class="header-overlay"></div>
    </div>
    <div class="viewport">
      <div class="page-intro d-flex justify-content-between">
        <div class="info-box">
          <div class="content">
            <h2>Direct hiring</h2>
            <p>
              We find, assess and present top IT professionals for employment in your organization,
              helping to grow your in-house capability with skilled and competent staff.
            </p>
            <router-link to="/services/direct-hiring" class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>

        <div class="info-box">
          <div class="content">
            <h2>Dedicated nearshore team</h2>
            <p>
              Create a natural extension of your business with a bespoke and dedicated team, working
              remotely from our managed office for medium and long-term needs.
            </p>
            <router-link to="/services/dedicated-nearshore-team" class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>

        <div class="info-box">
          <div class="content">
            <h2>Freelancing network</h2>
            <p>
              Explore and take advantage of our flexible and unique network of highly skilled IT
              professionals, available for short or medium-term projects, on-demand.
            </p>
            <router-link to="/services/freelancing-network" class="btn btn-primary"
              >Read more</router-link
            >
          </div>
          <div class="overlay"></div>
        </div>

        <div class="cite-box">
          <h3>It takes the right people to succeed</h3>
          <p>
            No company or project is the same. Choose from one of the services areas above to get
            access to the people you need, with a model that’s tailored to your business
            requirements
          </p>
        </div>
      </div>
    </div>

    <div
      class="background-mid-section d-flex align-items-center"
      style="background: url('img/homepage/partnership.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h3>
          We create bespoke and mutually beneficial partnerships for our clients and candidates.
        </h3>
        <p>
          As IT professionals ourselves, we have a deep understanding of successfully matching the
          right people for the right job. Headquartered in Sweden with a technical site in Europe’s
          IT hotspot, Bulgaria, no one’s better placed to help you grow and evolve your technical
          organization.
        </p>
        <router-link to="/about-us" class="btn btn-primary">Read more</router-link>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="expertise d-flex justify-content-between">
        <h3>Areas of Expertise</h3>

        <div class="expertise-box">
          <img src="/img/icons/software-development-2x.png" alt="Software Development" />
          Software Development
        </div>
        <div class="expertise-box">
          <img src="/img/icons/ux-ui-design-2x.png" alt="UX/UI Design" />
          UX/UI Design
        </div>
        <div class="expertise-box">
          <img src="/img/icons/quality-assurance-2x.png" alt="Quality Assurance" />
          Quality Assurance
        </div>
        <div class="expertise-box">
          <img src="/img/icons/technical-writing-2x.png" alt=" Technical Writing" />
          Technical Writing
        </div>
        <div class="expertise-box">
          <img src="/img/icons/icons8-workflow-100.png" alt="Technical Operations" />
          Technical Operations
        </div>
        <div class="expertise-box">
          <img src="/img/icons/technical-support-2x.png" alt="Technical Support" />
          Technical Support
        </div>
        <div class="expertise-box">
          <img src="/img/icons/technical-management-2x.png" alt="Technical Management" />
          Technical Management
        </div>
        <div class="expertise-box">
          <img src="/img/icons/technical-marketing-2x.png" alt="Technical Sales/Marketing" />
          Technical Sales/Marketing
        </div>
      </div>
    </div>

    <div class="viewport">
      <div class="call-to-action-boxes d-flex justify-content-between">
        <div
          class="box"
          style="background: url('img/homepage/join-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Join our team</h3>
            <p>
              We go further for our staff, so that they can go further for our clients. Find out
              more about what it means to work at Comstream.
            </p>
            <router-link to="/careers" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url('img/homepage/become-freelancer.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Become one of our freelancers</h3>
            <p style="margin-bottom: 43px">
              Let us connect you with our clients for interesting and quick-response projects.
            </p>
            <router-link to="/freelancers" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
      <Slider />
    </div>
  </section>
</template>

<script>
import Slider from '../components/utils/slider';
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Connecting IT Professionals to Growth Businesses | Comstream',
  components: {
    Slider,
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.homepage {
  .header {
    position: relative;
    min-height: 85vh;
    background: url('/img/homepage/header_home.jpg');
    background-size: cover;
    background-position: center top;
    .content {
      text-align: center;
      width: 1200px;
      z-index: 10;
      h1 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2.5rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        padding: 12px 20px;
        letter-spacing: 8px;
        width: 80%;
        margin: 12px auto 30px auto;
        span {
          display: block;
        }
      }
      .sub-title {
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .header-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      z-index: 0;
    }
  }
  .page-intro {
    margin: 12px 0 30px 0;
    flex-wrap: wrap;
    .info-box {
      position: relative;
      width: 32.5%;
      min-height: 550px;
      border-radius: 8px;
      overflow: hidden;
      .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 40px 20px;
        z-index: 10;
        text-align: center;
        h2 {
          text-align: center;
          color: #fff;
          font-weight: 600;
          font-size: 1.75rem;
          letter-spacing: 0.5px;
          margin: 12px 0;
        }
        p {
          font-size: 1.188rem;
          color: #fff;
          margin-bottom: 30px;
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        z-index: 0;
      }
      &:first-of-type {
        background: url('/img/homepage/diriect-hiring.jpg');
        background-size: cover;
        background-position: bottom;
      }
      &:nth-of-type(2) {
        background: url('/img/homepage/nearshore-team.jpg');
        background-size: cover;
        background-position: bottom;
      }
      &:nth-of-type(3) {
        background: url('/img/homepage/freelancing-network.jpg');
        background-size: cover;
        background-position: bottom;
      }
    }
    .cite-box {
      width: 100%;
      text-align: center;
      padding: 70px 0 40px 0;
      h3 {
        font-size: 3rem;
        font-weight: 700;
        letter-spacing: 1px;
        color: #383838;
        margin-bottom: 20px;
      }
      p {
        font-size: 1.125rem;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .expertise {
    padding: 70px 0;
    flex-wrap: wrap;
    h3 {
      text-align: center;
      width: 100%;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 1px;
      color: #353535;
      margin-bottom: 40px;
    }
    .expertise-box {
      display: flex;
      align-items: center;
      width: 24.5%;
      padding: 12px;
      font-weight: 600;
      font-size: 19px;
      color: #383838;
      img {
        width: 90px;
        height: 90px;
        flex-shrink: 0;
        margin-right: 12px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 576px) {
  .homepage {
    .header {
      min-height: auto;
      padding: 60px 0;
      .content {
        h1 {
          font-size: 1.8rem;
          letter-spacing: 0.5px;
          width: 100%;
        }
        .sub-title {
          font-size: 1rem;
          padding-right: 12px;
          padding-left: 12px;
        }
      }
    }
    .page-intro {
      display: block !important;
      .info-box {
        width: 100%;
        margin-bottom: 40px;
      }
      .cite-box {
        padding: 30px 0;
        h3 {
          font-size: 2rem;
        }
      }
    }
    .background-mid-section {
      height: auto;
      padding: 50px 0;
    }
    .expertise {
      display: block !important;
      h3 {
        font-size: 2rem;
      }
      .expertise-box {
        width: 100%;
      }
    }
  }
}
</style>
