<template>
  <carousel :autoplay="5000" :wrapAround="true" :itemsToScroll="6">
    <slide v-for="brand in brands" :key="brand.id">
      <img :src="`img/customer-logos/${brand.src}`" alt="logo" />
    </slide>
    <template #addons> </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      brands: [
        {
          id: 0,
          src: 'Academedia.png',
          alt: 'Academedia',
        },
        {
          id: 1,
          src: 'Addedo.png',
          alt: 'Addedo',
        },
        {
          id: 2,
          src: 'Allihoopa.png',
          alt: 'Allihoopa',
        },
        {
          id: 3,
          src: 'AXS.png',
          alt: 'AXS',
        },
        {
          id: 4,
          src: 'Barium.png',
          alt: 'Barium',
        },
        {
          id: 5,
          src: 'Blogg.se.png',
          alt: 'Blog.se',
        },
        {
          id: 6,
          src: 'Bokio-1024x380.png',
          alt: 'Bokio',
        },
        {
          id: 7,
          src: 'Bygghemma.png',
          alt: 'Bygghemma',
        },
        {
          id: 8,
          src: 'Carestream.png',
          alt: 'Carestream',
        },
        {
          id: 9,
          src: 'CellaVision.png',
          alt: 'CellaVision',
        },
        {
          id: 10,
          src: 'Challengermode.png',
          alt: 'Challengermode',
        },
        {
          id: 11,
          src: 'Ciber.png',
          alt: 'Ciber',
        },
        {
          id: 12,
          src: 'City_network-1024x187.png',
          alt: 'City Network',
        },
        {
          id: 13,
          src: 'Collectum.png',
          alt: 'Collectum',
        },
        {
          id: 83,
          src: 'Cygni_new.png',
          alt: 'Cygni_new',
        },
        {
          id: 14,
          src: 'Diakrit.png',
          alt: 'Diakrit',
        },
        {
          id: 15,
          src: 'ELITS.png',
          alt: 'Elits',
        },
        {
          id: 16,
          src: 'Enklare-1024x279.png',
          alt: 'Enklare',
        },
        {
          id: 17,
          src: 'ESRI.png',
          alt: 'ESRI',
        },
        {
          id: 18,
          src: 'Etraveli.png',
          alt: 'Etraveli',
        },
        {
          id: 19,
          src: 'Filimundus.png',
          alt: 'Filimundus',
        },
        {
          id: 20,
          src: 'Flowscape.png',
          alt: 'Flowscape',
        },
        {
          id: 21,
          src: 'Footway.png',
          alt: 'Footway',
        },
        {
          id: 22,
          src: 'Freedom_finance.png',
          alt: 'Freedom finance',
        },
        {
          id: 23,
          src: 'Gavagai.png',
          alt: 'Gavagai',
        },
        {
          id: 24,
          src: 'Gears_of_leo.png',
          alt: 'Gears of leo',
        },
        {
          id: 25,
          src: 'Gimi-1024x452.png',
          alt: 'Gimi',
        },
        {
          id: 26,
          src: 'Greatdays.png',
          alt: 'Greatdays',
        },
        {
          id: 27,
          src: 'Hemverket.png',
          alt: 'Hemverket',
        },
        {
          id: 28,
          src: 'INBooks-1024x328.png',
          alt: 'Inbooks',
        },
        {
          id: 29,
          src: 'Knightec.png',
          alt: 'Knightec',
        },
        {
          id: 30,
          src: 'Lysa.png',
          alt: 'Lysa',
        },
        {
          id: 31,
          src: 'MAG_Interactive.png',
          alt: 'Mag interactive',
        },
        {
          id: 32,
          src: 'Molnify.png',
          alt: 'Molnify',
        },
        {
          id: 33,
          src: 'Nyheter24.png',
          alt: 'Nyheter24',
        },
        {
          id: 34,
          src: 'Outnorth.png',
          alt: 'Outhnorth',
        },
        {
          id: 35,
          src: 'Outpost24.png',
          alt: 'Outpost24',
        },
        {
          id: 36,
          src: 'Phonera.png',
          alt: 'Phonera',
        },
        {
          id: 37,
          src: 'Q-Park.png',
          alt: 'Q-park',
        },
        {
          id: 38,
          src: 'Quickspin-1024x246.png',
          alt: 'Quickspin',
        },
        {
          id: 39,
          src: 'Savelend.png',
          alt: 'Savelend',
        },
        {
          id: 40,
          src: 'Seliro.png',
          alt: 'Seliro',
        },
        {
          id: 41,
          src: 'Sigma_Technology.png',
          alt: 'Sigma Technology',
        },
        {
          id: 42,
          src: 'Starweb.png',
          alt: 'Starweb',
        },
        {
          id: 43,
          src: 'Swesharp.png',
          alt: 'Swesharp',
        },
        {
          id: 44,
          src: 'System_verification.png',
          alt: 'System verification',
        },
        {
          id: 45,
          src: 'Telavox-1024x410.png',
          alt: 'Telavox',
        },
        {
          id: 46,
          src: 'Tradedoubler-1024x123.png',
          alt: 'Tradedoubler',
        },
        {
          id: 47,
          src: 'Zmarta.png',
          alt: 'Zmarta',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.carousel {
  margin-bottom: 40px;
  .carousel__slide {
    width: 13% !important;
    margin-right: 50px;
  }
}
img {
  width: 90%;
  margin: 0 auto;
  height: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  &:hover {
    filter: none;
  }
}
@media only screen and (max-width: 576px) {
  .carousel {
    margin-bottom: 40px;
    .carousel__slide {
      width: 50% !important;
      margin-right: 50px;
    }
  }
}
</style>
